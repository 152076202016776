<template>
  <form id="shipment-number-input-form" ref="form" @submit.prevent="submitForm">
    <atom-text-input
      :value="shipmentNumber as string"
      :data-id="DataIdElement.SHIPMENT_NUMBER_INPUT"
      name="shipmentNumber"
      placeholder="Z 123 4567 890"
      :disabled="!isMounted || loading"
      :loading="loading"
      type="text"
      :class="{ invalid: errors.shipmentNumber }"
      :append-icon="getInputStatusAppendIcon(!!errors.shipmentNumber, shipmentNumber)"
      @input="handleShipmentNumberChange"
      @paste="handleShipmentNumberChange"
      @blur="handleShipmentNumberChange"
      @enter="handleShipmentNumberChange($event, true)"
      @focus="emits(Emits.FOCUS)"
    >
      <span v-if="errors.shipmentNumber" class="error-text paragraph-small">{{
        t(errors.shipmentNumber)
      }}</span>
    </atom-text-input>

    <molecule-tooltip v-if="!withoutTooltip" :default-position="MoleculeTooltipPosition.RIGHT">
      <template #activator>
        <div
          data-id="shipment-number-tooltip"
          class="where-shipment-number-tooltip paragraph-small"
        >
          {{ t('shipmentNumberInputTooltipTitle') }}
        </div>
      </template>
      <template #content>
        <div
          class="shipment-number-tooltip-content d-flex flex-column gap-8 text-base-black text-start"
        >
          <p v-if="isMobile" class="heading-h6-heavy mb-8">
            {{ t('shipmentNumberInputTooltipTitle') }}
          </p>
          <p v-else class="paragraph-x-small-heavy text-base-black mb-4">
            {{ t('shipmentNumberInputTooltipTitle') }}
          </p>
          <div
            :class="{
              'paragraph-x-small': !isMobile,
              'paragraph text-base-grey': isMobile,
            }"
          >
            {{ t('shipmentNumberInputTooltipText') }}
          </div>
        </div>
      </template>
    </molecule-tooltip>

    <atom-button
      v-if="!withoutSubmitBtn"
      :type="ButtonType.SUBMIT"
      :data-id="DataIdElement.SHIPMENT_NUMBER_SUBMIT_BTN"
      class="shipment-number-submit-btn mt-32 w-100 justify-content-center paragraph"
      large
      :disabled="submitDisabled || loading"
    >
      {{ t('shipmentNumberInputBtn') }}
    </atom-button>
  </form>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import { defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import * as yup from 'yup'
import { ButtonType, Emits, MoleculeTooltipPosition, Status } from '~common/enums'
import { DataIdElement } from '~common/enums/tests.ts'
import { getFormattedShipmentNumber, getInputStatusAppendIcon } from '~common/helpers'
import type { ShipmentInputEmits, ShipmentInputProps } from '~common/types'
import { useGlobalStore } from '~stores/global.store.ts'

const AtomButton = defineAsyncComponent(() => import('~components/atoms/AtomButton.vue'))
const AtomTextInput = defineAsyncComponent(() => import('~components/atoms/AtomTextInput.vue'))
const MoleculeTooltip = defineAsyncComponent(
  () => import('~components/molecules/MoleculeTooltip.vue'),
)

const { t } = useI18n()

const globalStore = useGlobalStore()

const props = defineProps<ShipmentInputProps>()
const emits = defineEmits<ShipmentInputEmits>()

// Validation
const shipmentNumberSchema = yup
  .string()
  .test('shipmentNumber', 'shipmentNumberInputFormatRule', (value) => {
    if (!value) return true
    const digits8Regex = /^[0-9]{4} [0-9]{4}$/
    const generalRegex = /^Z [0-9]{3} [0-9]{4} [0-9]{3}$/i
    return digits8Regex.test(value) || generalRegex.test(value)
  })
const schema = yup.object({
  shipmentNumber: shipmentNumberSchema.required('shipmentNumberInputRequiredRule'),
})
const { handleSubmit, errors, values } = useForm({
  validationSchema: schema,
})

const submitDisabled = ref<boolean>(true)
const loading = ref(false)
const isMounted = ref(false)
watch(
  () => errors,
  (e) => {
    submitDisabled.value = !values.shipmentNumber ? true : !!e.value.shipmentNumber
    const error = e.value.shipmentNumber
    if (!props.withoutErrorBadge) {
      if (error) {
        globalStore.setMoleculeAlertBadge({
          title: t(`${error}BadgeTitle`),
          text: t(`${error}BadgeText`),
          status: Status.ERROR,
          visible: true,
        })
      } else if (values.shipmentNumber !== undefined) {
        globalStore.clearMoleculeAlertBadge()
      }
    }
  },
  { deep: true },
)

const { value: shipmentNumber, setValue } = useField('shipmentNumber')

const submitForm = handleSubmit((values) => {
  loading.value = true
  emits(Emits.SUBMIT, values.shipmentNumber)
})

watch(
  () => errors.value,
  (val) => {
    emits(Emits.ERROR, val)
  },
  { deep: true },
)

// Lifecycle hooks
onMounted(() => {
  if (props.defaultShipmentNumber) {
    handleShipmentNumberChange(props.defaultShipmentNumber)
  }
  isMounted.value = true
})

const handleShipmentNumberChange = (value?: string | null, keyupEnter = false) => {
  if (typeof value !== 'string') {
    return
  }

  let formattedValue = value.toUpperCase().replace(/[^Z\d]/g, '') || ''

  if (!props.required && !formattedValue) {
    formattedValue = ''

    setValue(formattedValue)
    emits(Emits.INPUT, formattedValue)
    return
  }

  formattedValue = getFormattedShipmentNumber(formattedValue)

  setValue(formattedValue)
  emits(Emits.INPUT, formattedValue)

  if (keyupEnter && !props.withoutSubmitBtn) {
    submitForm()
  }
}
</script>

<style lang="scss" scoped>
.where-shipment-number-tooltip {
  color: $grey-300;
  text-decoration: underline;
}

.where-shipment-number-tooltip:hover {
  color: $red-300;
  text-decoration: none;
}

.shipment-number-submit-btn {
  height: 50px;
}
</style>
